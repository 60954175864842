import React, { useEffect, useState, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  CaptionInput,
  ModalFooter,
  DatePickerWrapper,
  SelectWrapper,
  SelectItem,
  PreviewSection,
  EditButton,
  ModalLeft,
  ModalRight,
  Button,
  Toggle,
  ToggleInput,
  ToggleSlider,
  SliderSection,
  PreviewEditButton,
  CloseButton,
} from "./styles/PostModal.styled";
import { H2, H3, H4, NavButton } from "../pages/styles/global/main.style";
import { showError } from "../utils/toast";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import Instagram from "../assets/images/ig-logo.png";
import Facebook from "../assets/images/fb-logo.png";
import plusIcon from "../assets/images/plus-circle-icon.svg";
import editIconWhite from "../assets/images/edit-icon-white.svg";
import plusIconGreen from "../assets/images/plus-circle-icon-green.svg";
import { motion } from "framer-motion";
import {
  postModalSelectItemVariants,
  postModalCheckIconVariants,
  boostSectionVariants,
  itemVariants as fadeInUpVariants,
} from "../pages/styles/global/framer-motion-variants";

import { Slider, TextField } from "@mui/material";
import clockIcon from "../assets/images/clock.svg";
import axios from "axios";
import { PostBlockButtonSmall } from "./styles/PostBlock.styled";
import MagicPen from "../assets/images/magicpen-mini.svg";
import Lottie from "lottie-react";
import processingAnimation from "../assets/lottie/processing.json";
import styled from "styled-components";
import {
  ProductSelect,
  SearchInput,
  SelectedProductContainer,
  SliderContainer,
  SliderWrapper,
  SliderArrow,
  SwiperContainer,
} from "./styles/TestAdModal.styled";
import editIcon from "../assets/images/edit-icon.svg";
import { Input } from "./styles/ChatBot.styled";
import uploadIcon from "../assets/images/upload-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { ShopifyInputContainer } from "../pages/styles/ConnectChannels.styled";

const LottieWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowLeft = () => (
  <svg viewBox="0 0 24 24">
    <path d="M15.41 16.59L14 18l-6-6 6-6 1.41 1.41L10.83 12z" />
  </svg>
);

const ArrowRight = () => (
  <svg viewBox="0 0 24 24">
    <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z" />
  </svg>
);

const scrollSlider = (ref, direction) => {
  const container = ref.current;
  const scrollAmount = direction === "left" ? -200 : 200;
  container.scrollBy({ left: scrollAmount, behavior: "smooth" });
};

const ChannelSection = styled.div`
  min-height: 140px;
  margin-bottom: 20px;
`;

const TestAdBlock = ({
  id,
  channelId: initialChannelId,
  scheduledDate,
  caption,
  postType: initialPostType,
  previewUrl: initialPreviewUrl,
  postFile: initialPostFile,
  storyFile: initialStoryFile,
  boost,
  days = 2,
  budget = 20,
  selectedAdAccount: initialAdAccount,
  isUploading,
  isPosting,
  selectedProduct: initialSelectedProduct,
  productUrl: initialProductUrl,
  onUpdateField,
  onDelete,
}) => {
  const userDocument = useSelector((state) => state.user.userDocument);
  const [isGeneratingCaption, setIsGeneratingCaption] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(
    initialSelectedProduct
  );
  const [productUrl, setProductUrl] = useState(initialProductUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [channelId, setChannelId] = useState(initialChannelId);
  const [selectedAdAccount, setSelectedAdAccount] = useState(initialAdAccount);

  const [postType, setPostType] = useState(initialPostType);
  const [previewUrl, setPreviewUrl] = useState(initialPreviewUrl);
  const [postFile, setPostFile] = useState(initialPostFile);
  const [storyFile, setStoryFile] = useState(initialStoryFile);

  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showAdAccountModal, setShowAdAccountModal] = useState(false);

  // Gather connected channels from userDocument
  const getConnectedChannels = () => {
    const channels = [];

    if (userDocument?.facebookPages) {
      userDocument.facebookPages.forEach((page) => {
        channels.push({
          id: page.id,
          name: `${page.name}`,
          platform: "facebook",
        });
      });
    }

    if (userDocument?.instagramBusinessAccountId) {
      channels.push({
        id: userDocument.instagramBusinessAccountId,
        name: `${userDocument.instagramUsername}`,
        platform: "instagram",
      });
    }

    return channels;
  };

  // Get channels and ad accounts
  const connectedChannels = getConnectedChannels();
  const adAccounts = userDocument?.facebookAdAccounts || [];

  // Auto-select first items if not already selected
  useEffect(() => {
    if (!channelId && connectedChannels.length > 0) {
      const newChannelId = connectedChannels[0]?.id;
      setChannelId(newChannelId);
      onUpdateField("channelId", newChannelId);
    }
    if (!selectedAdAccount && adAccounts.length > 0) {
      const newAdAccountId = adAccounts[0]?.account_id;
      setSelectedAdAccount(newAdAccountId);
      onUpdateField("selectedAdAccount", newAdAccountId);
    }
  }, [connectedChannels, adAccounts]);

  // Update product URL when selected product changes
  useEffect(() => {
    if (selectedProduct?.url) {
      setProductUrl(selectedProduct.url);
      onUpdateField("productUrl", selectedProduct.url);
    }
  }, [selectedProduct]);

  // Add product fetching
  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/products`,
        {
          params: {
            shop: userDocument.shopifyShop,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Update local state when post type changes
  useEffect(() => {
    const currentFile = postType === "post" ? postFile : storyFile;
    setPreviewUrl(currentFile);
    onUpdateField("previewUrl", currentFile);
  }, [postType, postFile, storyFile]);

  const handleUpload = async (event, type) => {
    try {
      const file = event.target?.files[0];
      if (!file) return;

      if (!file.type.startsWith("image/")) {
        showError("Please upload an image file");
        return;
      }

      if (file.size > 4.75 * 1024 * 1024) {
        showError("File size must be less than 4.75MB");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", userDocument.uid);

      const timestamp = Date.now();
      const storagePath = `social-posts/${userDocument.uid}/${type}/${timestamp}-${file.name}`;
      formData.append(
        "metadata",
        JSON.stringify({
          contentType: file.type,
          storagePath: storagePath,
          bucketName: "ecommercebot-a076f.firebasestorage.app",
        })
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const fileUrl = `https://firebasestorage.googleapis.com/v0/b/ecommercebot-a076f.firebasestorage.app/o/${encodeURIComponent(
        storagePath
      )}?alt=media&token=${response.data.token}`;

      // Update both local and parent state
      if (type === "post") {
        setPostFile(fileUrl);
        onUpdateField("postFile", fileUrl);
      } else {
        setStoryFile(fileUrl);
        onUpdateField("storyFile", fileUrl);
      }

      setPreviewUrl(fileUrl);
      onUpdateField("previewUrl", fileUrl);

      // Update post type to match uploaded file type
      setPostType(type);
      onUpdateField("postType", type);
    } catch (error) {
      console.error("Error uploading file:", error);
      showError("Failed to upload file");
    }
  };

  const renderPreview = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {postType === "post" ? (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleUpload(e, "post")}
            style={{ display: "none" }}
            id={`post-upload-${id}`}
          />
          <label
            htmlFor={`post-upload-${id}`}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {postFile ? (
              <img
                src={postFile}
                alt="preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <>
                <img
                  src={uploadIcon}
                  alt="upload"
                  style={{ width: 30, height: 30, marginBottom: 16 }}
                />
                <span>Upload Post</span>
                <span style={{ fontSize: 12, color: "#666" }}>
                  Max Size: 4.75MB
                </span>
              </>
            )}
          </label>
        </>
      ) : (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleUpload(e, "story")}
            style={{ display: "none" }}
            id={`story-upload-${id}`}
          />
          <label
            htmlFor={`story-upload-${id}`}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {storyFile ? (
              <img
                src={storyFile}
                alt="preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <>
                <img
                  src={uploadIcon}
                  alt="upload"
                  style={{ width: 48, height: 48, marginBottom: 16 }}
                />
                <span>Upload Story</span>
                <span style={{ fontSize: 12, color: "#666" }}>
                  Max Size: 4.75MB
                </span>
              </>
            )}
          </label>
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (userDocument?.shopifyShop) {
      fetchProducts();
    }
  }, [userDocument]);

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const parseHtmlContent = (html) => {
    if (!html) return "";
    const temp = document.createElement("div");
    temp.innerHTML = html;
    const text = temp.textContent || temp.innerText;
    return text
      .replace(/\s+/g, " ")
      .trim()
      .split(/\n+/)
      .filter((line) => line.trim())
      .join("\n");
  };

  // Add AI caption generation handler
  const handleGenerateCaption = async () => {
    if (!previewUrl) {
      showError("Please add an image first");
      return;
    }

    try {
      setIsGeneratingCaption(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/social-manager/post/generate-caption`,
        { imageUrl: previewUrl },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.caption) {
        onUpdateField("caption", response.data.caption);
      }
    } catch (error) {
      console.error("Error generating caption:", error);
      showError(
        error.response?.data?.details ||
          "Failed to generate caption. Please try again."
      );
    } finally {
      setIsGeneratingCaption(false);
    }
  };

  const renderSelectedChannel = () => {
    const channel = connectedChannels.find((ch) => ch.id === channelId);
    if (!channel) return null;

    return (
      <SelectItem
        variants={postModalSelectItemVariants}
        initial="hidden"
        animate="visible"
        style={{
          margin: 0,
          height: "60px",
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
          width: "100%",
          backgroundColor: "#E8F5E9",
          position: "relative",
        }}
      >
        <div className="select-platform-name">
          <img
            src={channel.platform === "instagram" ? Instagram : Facebook}
            alt={channel.platform}
            style={{ width: "20px", marginRight: "10px" }}
          />
          <span>{channel.name}</span>
        </div>
        <img
          src={editIcon}
          alt="Edit"
          style={{
            position: "absolute",
            right: "20px",
            width: "20px",
            cursor: "pointer",
          }}
          onClick={() => setShowChannelModal(true)}
        />
      </SelectItem>
    );
  };

  // Channel selection modal
  const renderChannelModal = () => {
    if (!showChannelModal) return null;

    return (
      <ModalOverlay onClick={() => setShowChannelModal(false)}>
        <ShopifyInputContainer>
          <H2>Select Channel</H2>
          <div
            style={{
              marginBottom: "20px",
              overflowY: "auto",
              maxHeight: "300px",
            }}
          >
            {connectedChannels.map((channel) => (
              <div
                key={channel.id}
                style={{
                  padding: "10px",
                  margin: "8px 0",
                  border: `2px solid ${
                    channelId === channel.id ? "#007bff" : "#ddd"
                  }`,
                  borderRadius: "8px",
                  cursor: "pointer",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setChannelId(channel.id);
                  onUpdateField("channelId", channel.id);
                  setShowChannelModal(false);
                }}
              >
                <img
                  src={channel.platform === "instagram" ? Instagram : Facebook}
                  alt={channel.platform}
                  style={{ width: "24px", marginRight: "12px" }}
                />
                <div>
                  <div style={{ fontWeight: "bold" }}>{channel.name}</div>
                  <div
                    style={{
                      fontSize: "0.8em",
                      color: "#666",
                      marginTop: "4px",
                    }}
                  >
                    Platform: {channel.platform}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Button secondary onClick={() => setShowChannelModal(false)}>
              Cancel
            </Button>
          </div>
        </ShopifyInputContainer>
      </ModalOverlay>
    );
  };

  const renderSelectedAdAccount = () => {
    const account = adAccounts.find(
      (acc) => acc.account_id === selectedAdAccount
    );
    if (!account) return null;

    return (
      <SelectItem
        variants={postModalSelectItemVariants}
        initial="hidden"
        animate="visible"
        style={{
          margin: 0,
          height: "60px",
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
          width: "100%",
          backgroundColor: "#E8F5E9",
          position: "relative",
        }}
      >
        <span>{account.name}</span>
        <img
          src={editIcon}
          alt="Edit"
          style={{
            position: "absolute",
            right: "20px",
            width: "20px",
            cursor: "pointer",
          }}
          onClick={() => setShowAdAccountModal(true)}
        />
      </SelectItem>
    );
  };

  // Ad Account selection modal
  const renderAdAccountModal = () => {
    if (!showAdAccountModal) return null;

    return (
      <ModalOverlay onClick={() => setShowAdAccountModal(false)}>
        <ShopifyInputContainer>
          <H2>Select Ad Account</H2>
          <div
            style={{
              marginBottom: "20px",
              overflowY: "auto",
              maxHeight: "300px",
            }}
          >
            {adAccounts.map((account) => (
              <div
                key={account.id}
                style={{
                  padding: "10px",
                  margin: "8px 0",
                  border: `2px solid ${
                    selectedAdAccount === account.account_id
                      ? "#007bff"
                      : "#ddd"
                  }`,
                  borderRadius: "8px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                onClick={() => {
                  setSelectedAdAccount(account.account_id);
                  onUpdateField("selectedAdAccount", account.account_id);
                  setShowAdAccountModal(false);
                }}
              >
                <div style={{ fontWeight: "bold" }}>{account.name}</div>
                <div
                  style={{ fontSize: "0.8em", color: "#666", marginTop: "4px" }}
                >
                  Account ID: {account.account_id}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Button secondary onClick={() => setShowAdAccountModal(false)}>
              Cancel
            </Button>
          </div>
        </ShopifyInputContainer>
      </ModalOverlay>
    );
  };

  const postTypes = [
    { id: "post", name: "Post" },
    { id: "story", name: "Story" },
  ];

  return (
    <ModalContent
      $isTestAd
      variants={fadeInUpVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      onClick={(e) => e.stopPropagation()}
    >
      <CloseButton
        onClick={onDelete}
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          zIndex: 1001,
        }}
      >
        ×
      </CloseButton>
      {(isUploading || isPosting) && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.9)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Lottie
            animationData={processingAnimation}
            loop={true}
            style={{ width: 100, height: 100 }}
          />
          <H4 style={{ marginTop: "20px" }}>
            {isUploading ? "Uploading..." : "Creating post..."}
          </H4>
        </motion.div>
      )}

      <ModalLeft $isTestAd>
        <H2>Product URL (Optional)</H2>
        <Input
          fullWidth
          type="url"
          placeholder="Enter product URL"
          value={productUrl}
          onChange={(e) => {
            setProductUrl(e.target.value);
            onUpdateField("productUrl", e.target.value);
          }}
          style={{ marginBottom: "20px" }}
        />
        <H2>Select Product</H2>
        {!selectedProduct ? (
          <>
            <SearchInput
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <ProductSelect $isTestAd>
              {filteredProducts.map((product) => (
                <SelectItem
                  $isTestAd
                  variants={postModalSelectItemVariants}
                  initial="hidden"
                  whileHover="hover"
                  whileTap="tap"
                  animate={{
                    backgroundColor:
                      selectedProduct?.id === product.id
                        ? "#E8F5E9"
                        : "#ffffff",
                    transition: { duration: 0.2 },
                  }}
                  selected={selectedProduct?.id === product.id}
                  key={product.id}
                  onClick={() => {
                    setSelectedProduct(product);
                    onUpdateField("selectedProduct", product);
                  }}
                >
                  <div className="select-item-container">
                    <img
                      src={product.images[0]?.src || ""}
                      alt={product.title}
                    />
                    <div className="product-info">
                      <span id="product-title">{product.title}</span>
                      <span id="product-price">
                        ${product.variants[0]?.price}
                      </span>
                    </div>
                  </div>

                  <motion.img
                    variants={postModalCheckIconVariants}
                    initial="hidden"
                    animate="visible"
                    custom={selectedProduct?.id === product.id}
                    src={
                      selectedProduct?.id === product.id
                        ? plusIconGreen
                        : plusIcon
                    }
                    alt="Check"
                  />
                </SelectItem>
              ))}
              {filteredProducts.length === 0 && (
                <SelectItem>No products found</SelectItem>
              )}
            </ProductSelect>
          </>
        ) : (
          <SelectedProductContainer>
            <img
              id="product-image"
              src={selectedProduct?.image?.src || ""}
              alt={selectedProduct?.title}
            />
            <div className="product-title-price">
              <span id="product-title">{selectedProduct?.title}</span>
              <span id="product-price">
                ${selectedProduct?.variants[0]?.price}
              </span>
            </div>
            <span id="product-description">
              {parseHtmlContent(selectedProduct?.body_html)}
            </span>
            {selectedProduct && (
              <Button
                id="switch-product-button"
                secondary
                onClick={() => {
                  setSelectedProduct(null);
                  onUpdateField("selectedProduct", null);
                }}
              >
                <img src={editIcon} alt="Switch Product" /> Switch Product
              </Button>
            )}
          </SelectedProductContainer>
        )}
      </ModalLeft>

      <ModalLeft $isTestAd>
        <H2>Selected Ad Account</H2>
        {renderSelectedAdAccount()}
        {renderAdAccountModal()}

        <H2>Create a Caption</H2>
        <div
          style={{
            position: "relative",
            display: "flex",
            gap: "10px",
            alignItems: "flex-start",
          }}
        >
          <CaptionInput
            placeholder="Write a caption..."
            value={caption}
            onChange={(e) => onUpdateField("caption", e.target.value)}
          />
          <PostBlockButtonSmall
            style={{
              position: "absolute",
              bottom: "36px",
              right: "16px",
            }}
            onClick={handleGenerateCaption}
            disabled={isGeneratingCaption}
          >
            {isGeneratingCaption ? (
              <LottieWrapper>
                <Lottie
                  animationData={processingAnimation}
                  loop={true}
                  style={{
                    width: 20,
                    height: 20,
                    filter: "brightness(0.7)",
                  }}
                />
              </LottieWrapper>
            ) : (
              <img src={MagicPen} alt="Magic Pen" />
            )}
          </PostBlockButtonSmall>
        </div>

        <H2>Selected Channel</H2>
        {renderSelectedChannel()}
        {renderChannelModal()}

        <H3>The campaign will run for 2 days and cost $20</H3>
      </ModalLeft>
      <ModalRight $isTestAd>
        <PreviewSection $isTestAd>{renderPreview()}</PreviewSection>
      </ModalRight>
    </ModalContent>
  );
};

export default TestAdBlock;
