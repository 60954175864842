import { useState } from "react";
import TestAdBlock from "../../components/TestAdBlock";
import { MainContainer } from "../styles/global/main.style";
import { Button } from "../../components/styles/PostModal.styled";
import axios from "axios";
import { showError, showSuccess } from "../../utils/toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import ConnectChannelBlock from "../../components/ConnectChannelBlock";
import facebookLogo from "../../assets/images/fb-logo.png";

const TestAd = () => {
  // State for managing multiple test blocks
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [loadingPlatform, setLoadingPlatform] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);
  const dispatch = useDispatch();
  const [userDocument, setUserDocument] = useState(
    useSelector((state) => state.user.userDocument)
  );

  const userDoc = userDocument;

  console.log("USER DOC", userDocument);
  const [testBlocks, setTestBlocks] = useState([
    {
      id: 1,
      channelId: userDocument?.facebookPages?.[0]?.id || "",
      scheduledDate: null,
      caption: "",
      postType: "post",
      previewUrl: "",
      postFile: null,
      storyFile: null,
      boost: false,
      days: 1,
      budget: 20,
      selectedAdAccount:
        userDocument?.facebookAdAccounts?.[0]?.account_id || "",
      isUploading: false,
      isPosting: false,
      selectedProduct: null,
      productUrl: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  // Add a new test block
  const handleAddBlock = () => {
    const maxId = Math.max(...testBlocks.map((block) => block.id), 0);
    const newBlock = {
      id: maxId + 1,
      channelId:
        testBlocks[testBlocks.length - 1]?.channelId ||
        userDocument?.facebookPages?.[0]?.id ||
        "",
      scheduledDate: null,
      caption: "",
      postType: "post",
      previewUrl: "",
      postFile: null,
      storyFile: null,
      boost: false,
      days: 1,
      budget: 20,
      selectedAdAccount:
        testBlocks[testBlocks.length - 1]?.selectedAdAccount ||
        userDocument?.facebookAdAccounts?.[0]?.account_id ||
        "",
      isUploading: false,
      isPosting: false,
      selectedProduct: null,
      productUrl: "",
    };
    setTestBlocks([...testBlocks, newBlock]);
    // Scroll to bottom after adding new block
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  // Update a specific test block's state
  const updateTestBlock = (id, field, value) => {
    setTestBlocks(
      testBlocks.map((block) =>
        block.id === id ? { ...block, [field]: value } : block
      )
    );
  };

  console.log("PRODUCTS", testBlocks[0].selectedProduct);

  // Handle testing all blocks
  const handleTestAll = async () => {
    setIsLoading(true);
    let hasErrors = false;
    let successCount = 0;

    for (const block of testBlocks) {
      try {
        // Validate required fields
        if (!block.channelId) {
          throw new Error(`Block ${block.id}: Channel ID is required`);
        }
        if (!block.caption) {
          throw new Error(`Block ${block.id}: Caption is required`);
        }
        if (!block.previewUrl) {
          throw new Error(`Block ${block.id}: Media upload is required`);
        }
        if (!block.selectedAdAccount) {
          throw new Error(
            `Block ${block.id}: Ad Account selection is required`
          );
        }
        if (!block.selectedProduct) {
          throw new Error(`Block ${block.id}: Product selection is required`);
        }

        if (!block.days || block.days < 1) {
          throw new Error(`Block ${block.id}: Invalid boost days`);
        }
        if (!block.budget || block.budget < 1) {
          throw new Error(`Block ${block.id}: Invalid boost budget`);
        }

        // Update block status
        updateTestBlock(block.id, "isPosting", true);

        // Make API call to create post
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/social-manager/post`,
          {
            channelId: block.channelId,
            caption: block.caption,
            postType: block.postType,
            product: block.selectedProduct,
            productUrl: block.productUrl,
            adAccountId: block.selectedAdAccount,
            mediaUrl: block.previewUrl,
            platform: block.channelId.includes("instagram")
              ? "instagram"
              : "facebook",
            boost: true,
            boostDays: block.days,
            boostBudget: block.budget,
            scheduledDate: block.scheduledDate || new Date() + 3 * 60 * 1000,
            userStoreUrl: userDoc.shopifyShop,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        showSuccess(`Post created for block ${block.id}`);
        successCount++;
      } catch (error) {
        console.error(`Error posting block ${block.id}:`, error);
        showError(`Error: ${error.message}`);
        hasErrors = true;
      } finally {
        updateTestBlock(block.id, "isPosting", false);
      }
    }

    setIsLoading(false);

    // Only show success and clear state if all posts succeeded
    if (!hasErrors && successCount === testBlocks.length) {
      showSuccess("All posts created successfully");
      setTestBlocks([]);
    }
  };

  // Delete a test block
  const handleDeleteBlock = (id) => {
    console.log("Deleting block with ID:", id);
    const updatedBlocks = testBlocks.filter((block) => block.id !== id);
    console.log("Updated blocks:", updatedBlocks);
    setTestBlocks(updatedBlocks);
  };

  const isConnected = userDocument?.connectedChannels?.includes("facebook");

  console.log("isConnected", isConnected);

  const handleConnect = async (platform) => {
    setSelectedPlatform(platform);
    const platformLower = platform.toLowerCase();
    setLoadingPlatform(platformLower);

    if (platformLower === "facebook" && isConnected) {
      // Show confirmation dialog for Facebook reconnection
      if (
        !window.confirm(
          "Reconnecting Facebook will remove all existing Facebook and Instagram data including conversations, contacts, and posts. Are you sure you want to continue?"
        )
      ) {
        setLoadingPlatform(null);
        return;
      }
    }

    setLoading(true);
    setError("");

    try {
      if (platformLower === "facebook") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/facebook/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          setIsRedirecting(true);
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "gmail") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/gmail/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "instagram") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/instagram/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.success) {
          const updatedChannels = new Set([
            ...(userDocument.connectedChannels || []),
            "instagram",
          ]);

          dispatch(
            setUserDocument({
              ...userDocument,
              instagramBusinessAccountId: response.data.instagramAccount.id,
              instagramUsername: response.data.instagramAccount.username,
              connectedChannels: Array.from(updatedChannels),
            })
          );
        }
      }
    } catch (error) {
      setError(
        error.response?.data?.error || `Failed to connect to ${platform}`
      );
      console.error(`${platform} connection error:`, error);
    } finally {
      setLoading(false);
      setSelectedPlatform(null);
      setLoadingPlatform(null);
    }
  };

  return (
    <MainContainer $isTestAd={true}>
      {!isConnected ? (
        <ConnectChannelBlock
          name="Facebook"
          description="Connect your Facebook page to start sending messages."
          isOutlined={true}
          isConnected={isConnected}
          handleConnect={() => {
            handleConnect("facebook");
          }}
          isLoading={false}
          isComingSoon={false}
          img_url={facebookLogo}
        />
      ) : (
        <>
          {testBlocks.map((block) => (
            <TestAdBlock
              key={block.id}
              {...block}
              onUpdateField={(field, value) =>
                updateTestBlock(block.id, field, value)
              }
              onDelete={() => handleDeleteBlock(block.id)}
            />
          ))}
          <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
            <Button onClick={handleAddBlock}>Add Another Test</Button>
            <Button
              primary
              onClick={handleTestAll}
              disabled={testBlocks.length === 0 || isLoading}
            >
              Test All
            </Button>
          </div>
        </>
      )}
    </MainContainer>
  );
};

export default TestAd;
