import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import {
  ChatBotContainer,
  ChatButton,
  ChatWindow,
  ChatHeader,
  ChatMessages,
  Message,
  InputContainer,
  Input,
  Greeting,
  CloseButton,
  ChatWithUsButton,
  ChatWidgetSendButton,
  OnlineCircle,
} from "./styles/ChatBot.styled";
import { H3, H4 } from "../pages/styles/global/main.style";
import close from "../assets/images/cancel-icon.svg";
import send from "../assets/images/send.svg";
import processingAnimation from "../assets/lottie/processing.json";
import Lottie from "lottie-react";
import { motion, AnimatePresence } from "framer-motion";

const ChatBot = ({ agentName, brandColor, avatar, enabled, logoUrl }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [greetingClosed, setGreetingClosed] = useState(
    localStorage.getItem("greetingClosed") === "false" ? true : false
  );
  const messagesEndRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isProcessing, setIsProcessing] = useState(false);
  const { userDocument } = useSelector((state) => state.user);
  const userId = userDocument?.uid;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isOpen]);

  const handleSend = async () => {
    if (!input.trim()) return;
    setIsProcessing(true);

    // Store current input and clear it immediately for better UX
    const currentInput = input;
    setInput("");

    // Add user message immediately
    const userMessage = {
      id: Date.now(),
      content: currentInput,
      senderId: userId,
      timestamp: new Date(),
    };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/fast-trainer/chat`,
        {
          userId: userId,
          message: currentInput,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Add bot response
      const botMessage = {
        id: Date.now() + 1,
        content: response.data.response,
        senderId: "bot",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      // Add error message
      const errorMessage = {
        id: Date.now() + 1,
        content: "Sorry, there was an error processing your message.",
        senderId: "bot",
        timestamp: new Date(),
        isError: true,
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleGreetingClose = () => {
    setGreetingClosed(true);
    localStorage.setItem("greetingClosed", true);
  };

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;
    setMousePosition({ x, y });
  };

  // Function to determine if text should be white or dark
  const getContrastColor = (hexcolor) => {
    // Remove the # if present
    const hex = hexcolor.replace("#", "");

    // Convert to RGB
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Calculate brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return white for dark backgrounds, dark for light backgrounds
    return brightness > 128 ? "#000000" : "#FFFFFF";
  };

  // Format message timestamp
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return "";

    if (timestamp.toDate && typeof timestamp.toDate === "function") {
      return timestamp.toDate().toLocaleTimeString();
    }

    if (timestamp instanceof Date) {
      return timestamp.toLocaleTimeString();
    }

    return "";
  };

  useEffect(() => {
    console.log(messages);

    messages.forEach((message) => {
      console.log("User message:", message);
      const isBot = message.senderId === userId;

      console.log("isBot", isBot);

      console.log("Bot message:", message);
    });
  }, [messages]);

  return (
    <ChatBotContainer>
      {!isOpen && !greetingClosed && (
        <Greeting>
          <CloseButton onClick={() => handleGreetingClose()}>
            <img src={close} alt="Close" />
          </CloseButton>
          <H3>Do you have any questions? 😊</H3>
          <div className="online-status">
            <OnlineCircle />
            <H4 id="online-status-text">{agentName} is Online</H4>
          </div>
          <ChatWithUsButton
            style={{
              background: brandColor,
              color: getContrastColor(brandColor),
            }}
            onClick={() => setIsOpen(true)}
          >
            Chat with us
          </ChatWithUsButton>
        </Greeting>
      )}
      <ChatButton
        style={{
          background: brandColor,
          color: getContrastColor(brandColor),
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="chat-button-icon">
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={getContrastColor(brandColor)}
              className="size-3"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={getContrastColor(brandColor)}
              className="size-3"
            >
              <path
                fillRule="evenodd"
                d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </ChatButton>

      {isOpen && (
        <ChatWindow>
          <ChatHeader
            style={{
              background: brandColor,
              color: getContrastColor(brandColor),
            }}
          >
            {logoUrl && <img src={logoUrl} alt={agentName} />}
            <div className="chat-header-text">
              {" "}
              Chat with {agentName}!
              <div className="online-status">
                <OnlineCircle
                  style={{
                    border: `1px solid ${getContrastColor(brandColor)}`,
                  }}
                />
                <H4
                  id="response-time"
                  style={{
                    color: getContrastColor(brandColor),
                  }}
                >
                  Team responds fast
                </H4>
              </div>
            </div>
          </ChatHeader>

          <ChatMessages>
            <AnimatePresence>
              {messages.map((message) => (
                <motion.div
                  key={message.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Message
                    $isBot={message.senderId !== userId}
                    $brandColor={brandColor}
                  >
                    <div className="message-bubble">{message.content}</div>
                    <div className="message-time">
                      {formatMessageTime(message.timestamp)}
                    </div>
                  </Message>
                </motion.div>
              ))}
              {isProcessing && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <Message $isBot={true} $brandColor={brandColor}>
                    <div className="message-bubble">
                      <Lottie
                        animationData={processingAnimation}
                        loop={true}
                        style={{
                          width: 30,
                          height: 30,
                          filter: "brightness(0.7)",
                        }}
                      />
                    </div>
                  </Message>
                </motion.div>
              )}
            </AnimatePresence>
            <div ref={messagesEndRef} />
          </ChatMessages>

          <InputContainer>
            <Input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Type a message..."
            />
            <ChatWidgetSendButton
              style={{
                background: brandColor,
                color: getContrastColor(brandColor),
              }}
              onClick={handleSend}
              onMouseMove={handleMouseMove}
              $mouseX={mousePosition.x}
              $mouseY={mousePosition.y}
            >
              <img
                src={send}
                alt="Send"
                style={{
                  filter:
                    getContrastColor(brandColor) === "#000000"
                      ? "brightness(0)"
                      : "none",
                }}
              />
            </ChatWidgetSendButton>
          </InputContainer>
        </ChatWindow>
      )}
    </ChatBotContainer>
  );
};

export default ChatBot;
