import styled from "styled-components";
import theme from "../../pages/styles/global/theme";
import { NavButton } from "../../pages/styles/global/main.style";
import { SendButton } from "../../pages/styles/Inbox.styled";

export const ChatBotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
`;

export const Greeting = styled.div`
  position: absolute;
  bottom: 100px;
  right: 20px;
  background: ${theme.color.white};
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 340px;

  animation: fadeIn 0.3s ease-in;

  h3 {
    margin: 0;
    color: ${theme.color.textPrimary};
    font-size: 1rem;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .online-status {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;

    #online-status-text {
      font-weight: 500;
      font-family: ${theme.font.fontFamilyPrimary};
      color: ${theme.color.success};
    }
  }

  .support-team {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid ${theme.color.white};

      &:not(:first-child) {
        margin-left: -12px;
      }
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ChatButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }

  .chat-button-icon {
    width: 33px;
    height: 33px;
  }
`;

export const ChatWithUsButton = styled(NavButton)`
  width: 100%;
  border-radius: 12px;
`;

export const ChatWindow = styled.div`
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 340px;
  height: 420px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;

  animation: ${(props) => (props.$isClosing ? "springOut" : "springIn")} 0.5s
    cubic-bezier(0.23, 1, 0.32, 1.2);
  transform-origin: bottom right;

  @keyframes springIn {
    0% {
      transform: translateY(20px) scale(0.95);
    }
    60% {
      transform: translateY(-4px) scale(1.01);
    }
    80% {
      transform: translateY(2px) scale(1);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }

  @keyframes springOut {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.01);
    }
    100% {
      transform: translateY(20px) scale(0.95);
    }
  }
`;

export const ChatHeader = styled.div`
  padding: 15px;
  background: ${theme.color.primary};
  color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 16px;
  font-weight: 500;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  #response-time {
    font-size: 12px;
    font-weight: 400;
    color: white;
  }

  .online-status {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;

    h4 {
      margin: 0;
    }
  }

  .chat-header-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const OnlineCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${theme.color.success};
`;

export const ChatMessages = styled.div`
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  height: 280px;

  /* Hide scrollbar while keeping functionality */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

export const Message = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: ${(props) => (props.$isBot ? "#f0f0f0" : props.$brandColor)};
  color: ${(props) => (props.$isBot ? "inherit" : "white")};
  margin-left: ${(props) => (props.$isBot ? "0" : "auto")};
  border-radius: ${(props) =>
    props.$isBot ? "10px 10px 10px 0px" : "10px 10px 0 10px"};
  max-width: 70%;

  animation: messageSpring 0.4s cubic-bezier(0.23, 1, 0.32, 1.2);
  transform-origin: ${(props) => (props.$isBot ? "top left" : "top right")};

  @keyframes messageSpring {
    0% {
      opacity: 0;
      transform: translateY(10px) scale(0.95);
    }
    70% {
      transform: translateY(-2px) scale(1.01);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  border: none;
  width: 100%;
  position: relative;
  border-radius: 12px 0px 0px 12px;
`;

export const ChatWidgetSendButton = styled(SendButton)`
  background: none;
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1.2);
  position: relative;
  overflow: hidden;
  border-radius: 0px 12px 12px 0px;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }

  img {
    transition: all 0.2s ease-out;
    position: relative;

    @media (hover: hover) {
      &:hover {
        transform: translate(
          calc(5% + ${(props) => props.$mouseX}px * 0.15),
          calc(5% + ${(props) => props.$mouseY}px * 0.15)
        );
      }
    }
  }
`;

export const Input = styled.input`
  border-radius: 5px;
  border: none;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 44px;
  padding-right: 20px;
  border: 1px solid ${theme.color.gray[300]};
  background: #fff;
  margin-bottom: 0px;
  font-family: ${theme.font.fontFamilyPrimary};

  &::placeholder {
    color: ${theme.color.textSecondary};
    transition: color 0.2s;
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: ${theme.color.textTertiary};
    }
  }
`;
