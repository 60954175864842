import { H1 } from "../../pages/styles/global/main.style";
import {
  CompleteStepContainer,
  FeatureItem,
  FeaturesContainer,
  TextContainer,
} from "../styles/CompleteStep.styled";
import { useNavigate } from "react-router-dom";

import chatIcon from "../../assets/images/chat.svg";
import testIcon from "../../assets/images/test.svg";
import bookIcon from "../../assets/images/book.svg";
import calendarIcon from "../../assets/images/calendarDots.svg";
import { AuthButton } from "../../pages/styles/Auth.styled";
import { useState } from "react";

const CompleteStep = ({ onStartTrial }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // Array of features with their names, descriptions, and icons
  const features = [
    {
      name: "Multi-Channel Customer Service",
      description: "Manage queries across platforms",
      icon: "chat", // Using emoji as placeholder icon
    },
    {
      name: "Creative Testing",
      description: "Predict ad profitability before spending.",
      icon: "test", // Using emoji as placeholder icon
    },
    {
      name: "AI-Powered Knowledge Base",
      description: "Provide smarter, AI-powered customer responses.",
      icon: "book", // Using emoji as placeholder icon
    },
    {
      name: "Content Scheduling & Boosting",
      description: "Plan, test, and optimize for better engagement.",
      icon: "calendar", // Using emoji as placeholder icon
    },
  ];

  const icons = {
    chat: chatIcon,
    test: testIcon,
    book: bookIcon,
    calendar: calendarIcon,
  };

  const handleStartTrial = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await onStartTrial();
      navigate("/social-manager/stats");
    } catch (error) {
      console.error("Error starting trial:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CompleteStepContainer>
      <TextContainer>
        <div className="header">
          Your 7-day trial on the <span>Success</span> plan
        </div>
        <div className="description">
          Unlock EcommerceBot <span>Success</span> with a 7-day trial and access
          all features to grow your e-commerce business.
        </div>
        <div className="button">
          <AuthButton onClick={handleStartTrial} disabled={isLoading}>
            {isLoading ? "Processing..." : "Start 7-Day Trial"}
          </AuthButton>
          <span>Cancel Anytime During Trial</span>
        </div>
      </TextContainer>
      <FeaturesContainer>
        {features.map((feature) => (
          <FeatureItem key={feature.name}>
            <img src={icons[feature.icon]} alt={feature.name} />
            <div className="text">
              <span id="feature-name">{feature.name}</span>
              <span id="feature-description">{feature.description}</span>
            </div>
          </FeatureItem>
        ))}
      </FeaturesContainer>
    </CompleteStepContainer>
  );
};

export default CompleteStep;
