import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../services/authService";
import { setUser, setToken, setProfile } from "../../store/slices/userSlice";
import axios from "axios";
import {
  AuthContainer,
  AuthForm,
  AuthInput,
  AuthButton,
  AuthLink,
  ErrorMessage,
  AuthImageContainer,
  AuthFormContainer,
  PhoneInputHint,
  PhoneInputGroup,
  AreaCodeSelect,
  BenefitsContainer,
  RegisterCreativeContainer,
  OtherOptionsContainer,
  OtherOptionsButton,
  OrSeparator,
} from "../styles/Auth.styled";
import AuthVideo from "../../components/AuthVideo";
import eyeSlash from "../../assets/images/eye-slash.svg";
import RegisterModal from "../../components/RegisterModal";
import registerCreative from "../../assets/images/registerbackgrounda.png";
import { MainContainer } from "../styles/global/main.style";
import emailIcon from "../../assets/images/email-icon.svg";
import passwordIcon from "../../assets/images/lock.svg";
import googleIcon from "../../assets/images/google-icon.svg";
import facebookIcon from "../../assets/images/facebook.svg";
import { showSuccess } from "../../utils/toast";

// Function to record metrics
const recordMetric = async (event, data) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/internalMetrics/registration-metrics`,
      {
        event,
        data: {
          ...data,
          version: "A",
        },
      }
    );
  } catch (error) {
    console.error("Failed to record metric:", error);
  }
};

const RegisterA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    phoneNumber: "",
    platform: "shopify", // Default platform
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  // Handle registration submission
  const handleRegister = async (e) => {
    e.preventDefault(); // Add this to prevent form submission
    setError("");
    setLoading(true);

    // Record registration attempt
    recordMetric("registration_attempt", {
      email: formData.email,
    });

    try {
      const { user, token, profile } = await register(
        formData.email,
        formData.password
      );

      dispatch(setUser(user));
      dispatch(setToken(token));
      if (profile) {
        dispatch(setProfile(profile));
      }

      // Record successful registration
      recordMetric("registration_success", {
        email: formData.email,
        userId: user.id,
      });

      navigate("/social-manager/stats?step=1");
    } catch (error) {
      console.error("Registration error:", error);

      // Handle Firebase specific errors
      if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else if (error.message) {
        setError(error.message);
      } else {
        setError("Registration failed. Please try again.");
      }

      //if error is already connection then show "Email already in use login?"
      if (error.message.includes("already in use")) {
        setError(
          <span>
            Email already in use. <Link to="/login">Login instead?</Link>
          </span>
        );
      }

      // Record registration failure
      recordMetric("registration_failure", {
        email: formData.email,
        error: error.message || "Unknown error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtherOptions = () => {
    // Record when users click other options
    recordMetric("other_options_click", {
      type: "social",
    });
    showSuccess("This is coming soon! Please use email for now!");
  };

  return (
    <AuthContainer $isRegisterA>
      <AuthFormContainer $isRegisterA>
        <AuthForm onSubmit={handleRegister} $isRegisterA>
          <div className="header">
            <h1>Create your account</h1>
            <h2>
              <span>More revenue in 90 days</span> or its free
            </h2>
          </div>

          <div className="form-input">
            <p>Email</p>
            <div className="form-container" style={{ position: "relative" }}>
              <AuthInput
                $isRegisterA
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
              <div className="icon">
                <img src={emailIcon} alt="email" />
              </div>
            </div>
          </div>

          <div className="form-input">
            <p>Password</p>
            <div className="form-container" style={{ position: "relative" }}>
              <div className="icon">
                <img src={passwordIcon} alt="show password" />
              </div>
              <AuthInput
                $isRegisterA
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword ? (
                  "👁️"
                ) : (
                  <img src={eyeSlash} alt="show password" />
                )}
              </button>
            </div>
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <AuthButton
            style={{ marginTop: "20px" }}
            type="submit"
            disabled={loading}
          >
            {loading ? "Creating Account..." : "Sign Up"}
          </AuthButton>
          <OrSeparator>
            <div className="separator" />
            <p style={{ whiteSpace: "nowrap" }}>Or Sign Up with</p>
            <div className="separator" />
          </OrSeparator>
          <OtherOptionsContainer>
            <OtherOptionsButton onClick={handleOtherOptions}>
              <img src={googleIcon} alt="google" />
              <p>Google</p>
            </OtherOptionsButton>
            <OtherOptionsButton onClick={handleOtherOptions}>
              <img src={facebookIcon} alt="facebook" />
              <p>Facebook</p>
            </OtherOptionsButton>
          </OtherOptionsContainer>
          <AuthLink to="/login">
            Already have an account?&nbsp;&nbsp;<span>Login</span>
          </AuthLink>
        </AuthForm>
        <h4>
          By creating an account, you agree to our{" "}
          <Link to="https://www.ecommercebot.com/legal">terms of use</Link>
        </h4>
      </AuthFormContainer>

      <RegisterCreativeContainer>
        <div className="header">
          <h1>AI-powered eCommerce made simple.</h1>
          <h2>Join 400+ stores. Start boosting sales today!</h2>
          <img id="img" src={registerCreative} alt="Register Creative" />
        </div>
      </RegisterCreativeContainer>
    </AuthContainer>
  );
};

export default RegisterA;
